import React, {useState, useEffect} from 'react';
import {Table, Container, Form, FormControl, Toast, Tooltip, OverlayTrigger, Row, Col, Badge} from 'react-bootstrap'
import Layout from "../../components/layout"
import RightSidebar from "../../components/sidebar/rightSideBar"
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import GenericPagination from '../../components/genericPagination';
import GroupingFilter from '../../components/filters/groupingFilter'
import StandardModal from '../../components/modal';

import Fetch from '../../helpers/fetch';
import constants from '../../helpers/constants';
import {DOSDDMMYYYYFormat} from '../../helpers/dateTime';

const TagOrders = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [orders, setOrders] = useState([]);
  const [orderGroupings, setOrderGroupings] = useState([]);
  const [orderGroupingFilter, setOrderGroupingFilter] = useState('');
  const [pagination, setPagination] = useState({});
  const [custId, setCustId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [selectedOrder, setSelectedOrder] = useState({})
  const [page, setPage] = useState(1);
  const [rowLimit, setRowLimit] = useState(25);
  const [showToast, setShowToast] = useState(false);
  const [errorMessage, seterrorMessage] = useState('')
  const [showModal, setshowModal] = useState(false)
  const [showRightSidebar, setshowRightSidebar] = useState(false);

  const getOrders = () => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/orders/tag`;

    if (page) {
      queryParams.push(`page=${page}`)
    }

    if (custId) {
      queryParams.push(`custId=${custId}`)
    }
    if (orderId) {
      queryParams.push(`orderId=${orderId}`)
    }

    if (orderGroupingFilter) {
      queryParams.push(`tag=${orderGroupingFilter}`)
    }

    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }

    setShowLoader(true)
    Fetch.get(`${url}${queryString}`)
    .then(res => {
      if(res.error) {
        setShowToast(true);
        seterrorMessage(res.error);
        setShowLoader(false);
        return;
      }
      setOrders(res.data);
      setPagination(res.pagination);
      setShowLoader(false);
      setshowRightSidebar(false);
    })
  }

  const getOrderGroupings = () => {
    Fetch.get(`${constants.BACKEND_URL}/order-groupings`)
    .then(res => {
      if(res.error) {
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      setOrderGroupings(res.data);
    })
  }

  const getOrderItemFromOrdersArray = (orderId) => {
    if (orders.length > 0) {
      const orderItem = orders.filter(item => {
        return item.id == Number(orderId)
      })
      return orderItem[0];
    }
    
  }
  
  useEffect(() => {
    if (custId) {
      const timeOutId = setTimeout(() => {
        getOrders();
        getOrderGroupings();
      }, constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getOrders();
      getOrderGroupings();

    }
    return () => {
      
    }
  }, [page, custId, orderId, orderGroupingFilter])

  const handleOnGroupChange = (e) => {
    const orderId = e.target.dataset.id;
    const groupId = e.target.value;
    const data = {
      orderId,
      groupId
    }
    // Update 
    const url = `${constants.BACKEND_URL}/orders/${orderId}`
    Fetch.patch(url, JSON.stringify(data)).then(res => {
      if(res.error) {
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      getOrders()
    })
  }

  const searchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const searchOrderId = (e) => {
    setOrderId(e.target.value);
    setPage(1);
  }
  
  const OrderGroupings = ({items, currentSelected, datasetId}) => {
    return (
      <select value={currentSelected} data-id={datasetId} className="form-control status-dropdown" onChange={handleOnGroupChange}>
          <option value=''>-Select One-</option>
        {
          items && items.length ? items.map((x,idx) => 
            <option 
              key={idx} 
              value={x.id} 
              
            >
              {x.groupName}
            </option>
          ) : <> </>
        }
      </select>
    )
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const handleOnClearAllFilter = (e) => {
    setPage(1);
    setCustId('');
    setOrderId('');
    setOrderGroupingFilter('');
  }

  const handleOnFilterPillClick = (e) => {
    const filter = e.target.dataset.filter
    switch (filter) {
      case 'custId':
        setCustId('')
        break;
      case 'orderId':
        setOrderId('')
        break;
      case 'orderGroupingFilter':
        setOrderGroupingFilter('')
        break;
      default:
        break;
    }
    
  }

  const handleOnGroupingChange = (e) => {
    setOrderGroupingFilter(e.target.value);
    setPage(1);
  }

  const handleOnClearGroupNameClick = (e) => {
    const orderId = e.target.dataset.id
    setshowModal(true);
    setSelectedOrder(getOrderItemFromOrdersArray(orderId));
  }

  const handleModalConfirm = () => {
    const data = {
      orderId: selectedOrder.id,
      groupId: null,
    }
    // Update 
    const url = `${constants.BACKEND_URL}/orders/${selectedOrder.id}`
    Fetch.patch(url, JSON.stringify(data)).then(res => {
      if(res.error) {
        setShowToast(true);
        seterrorMessage(res.error);
        return;
      }
      getOrders()
    })
    setshowModal(false);
  }
  
  const handleModalClose = () => {
    setshowModal(false);
  }
  

  const groupNameField = (item) => {
    return (
        item.group_name && 
        <>
          
          <span 
            className="oi oi-circle-x pointer" 
            onClick={handleOnClearGroupNameClick}
            data-id={item.id}
          >    
          </span>&nbsp;
          {item.group_name}
        </>
    )
  }

  const toggleRightSidebar = (e) => {
    setSelectedOrder(getOrderItemFromOrdersArray(e.target.dataset.id))
    if (Number(e.target.dataset.custId) !== Number(selectedOrder.custId)) {
      setshowRightSidebar(!showRightSidebar)
      setshowRightSidebar(true);
    } else {
      setshowRightSidebar(!showRightSidebar)
    }
  }

  const handleOnSidebarClose = () => {
    setshowRightSidebar(false);
  }
  
  
  return (
    <Layout pageInfo={{ pageName: "tagOrders" }}>
      <Loader show={showLoader}></Loader>
      <SEO title="Tag Orders page" />
      <Row>
        <Col className="text-center mb-2">
          <h5>Assign Orders</h5>
          <h6><small>- Showing Open Orders Only -</small></h6>
        </Col>
      </Row>
      <Container>
        <Row>
          <GroupingFilter
            show={true}
            orderGroupingFilter={orderGroupingFilter}
            handleOnGroupingChange={handleOnGroupingChange}
            orderGroupings={orderGroupings}
          ></GroupingFilter>
          <Col>
            <Form.Group as={Row}  className="mr-2 mb-2">
              <Form.Label column sm={3}>Cust ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={custId}               
                  onChange={searchCustId}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group as={Row}  className="mr-2 mb-2">
              <Form.Label column sm={3}>Order ID</Form.Label>
              <Col>
                <FormControl
                  type="text"
                  placeholder="Search..." 
                  value={orderId}               
                  onChange={searchOrderId}
                />
              </Col>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <small><a href="#" onClick={handleOnClearAllFilter}>Clear All Filter</a> </small>
            <span onClick={handleOnFilterPillClick}>
              {custId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Cust ID: {custId} 
                  <span className="oi oi-circle-x ml-2" data-filter="custId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {orderId && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Order ID: {orderId} 
                  <span className="oi oi-circle-x ml-2" data-filter="orderId" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              {orderGroupingFilter && 
                <Badge pill variant="info" className="ml-1 mr-1">
                  Tag: {orderGroupingFilter} 
                  <span className="oi oi-circle-x ml-2" data-filter="orderGroupingFilter" style={{cursor:'pointer'}}></span>
                </Badge> 
              }
              
            </span>
          </Col>
        </Row>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                Cust ID
                <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip className='text-left' id={`tooltip`}>
                        Click on Cust Id to see additional info
                      </Tooltip>
                    }
                  >
                    <span className="pointer oi oi-question-mark oi-question-mark-custom"></span>
                  </OverlayTrigger>
              </th>
              <th>Order Id</th>
              <th>Group Name</th>
              <th>Set Group</th>
            </tr>
          </thead>
          <tbody>
            {orders && Array.isArray(orders) && 
              orders.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <span className="pointer" data-cust-id={item.custId} data-id={item.id} onClick={toggleRightSidebar}>{item.custId}</span>
                    </td>
                    <td>{item.id}</td>
                    <td>{groupNameField(item)}</td>
                    <td>
                      <OrderGroupings 
                        items={orderGroupings}
                        currentSelected={item.group_id ? item.group_id: ''}
                        datasetId={item.id}
                      >
                        
                      </OrderGroupings>
                    </td>
                  </tr>
                )
              })
            }
            
          </tbody>
        </Table>
        {pagination ? 
          <GenericPagination 
            pagination={pagination} 
            rowLimit={rowLimit}
            handleOnPaginationClick={handleOnPaginationClick}
          ></GenericPagination> : <></>
        }
        <Toast 
          show={showToast} 
          onClose={() => {setShowToast(false)}}
          autohide
          style={{
            position: 'absolute',
            top: '1vh',
            'minHeight': '2vh',
            right: 0,
          }}
        >
          
            <Toast.Body><span className="oi oi-warning text-danger"></span><strong className="mr-auto text-danger">{errorMessage}</strong></Toast.Body>
        </Toast>

      </Container>
      <StandardModal 
        onConfirm={handleModalConfirm}
        onClose={handleModalClose} 
        show={showModal}
        title="Remove order from group"
        body={`Are you sure you want to remove order (${selectedOrder.id}) from group (${selectedOrder.group_name})?`}
      >
      </StandardModal>
      <RightSidebar 
        show={showRightSidebar}
        closeFn={handleOnSidebarClose}
        state={selectedOrder}
        >
          <h5 className="text-center mb-2">Additional Info</h5>  
          <Table>
            <thead>
              <tr>
                <th>Label</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>New - Cust ID</td>
                <td>{selectedOrder.custId}</td>
              </tr>
              <tr>
                <td>Old - Cust ID</td>
                <td>{selectedOrder.cust_id}</td>
              </tr>
              <tr>
                <td>Order ID</td>
                <td>{selectedOrder.id}</td>
              </tr>
              <tr>
                <td>Group Name</td>
                <td>{selectedOrder.group_name}</td>
              </tr>
              <tr>
                <td>Consultant Name</td>
                <td>{selectedOrder.consultant_name}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td>{selectedOrder.name}</td>
              </tr>
              <tr>
                <td>Phone</td>
                <td>{selectedOrder.phone}</td>
              </tr>
              <tr>
                <td>DOS</td>
                <td>{DOSDDMMYYYYFormat(selectedOrder.time)}</td>
              </tr>
              
            </tbody>
          </Table>
        </RightSidebar>
    
    </Layout>
  )
}

export default TagOrders